import React, { useState } from "react";
import Header from "../Component/header.tsx";
import img2 from "../assets/Group 4.png";
import img3 from "../assets/img2.png";
import Footer from "../Component/footer.tsx";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import Rectangle from "../assets/Rectangle 46.png";

import review from "../assets/bxs_quote-right.png";

function About() {
  const [contactData, setContactData] = useState({
    doctor: "Dr. Suresh",
    name: "",
    email: "",
    phone_number: "",
    message: "",
  });

  const handleChange = (event) => {
    setContactData((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = () => {
    const { doctor, name, email, phone_number, message } = contactData;
    if (!name || !doctor || !email || !phone_number || !message) {
      console.log("empty");
      return;
    }
    try {
      fetch("http://localhost/charterderm-backend/public/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contactData),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          console.log(data);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Header />
      <section>
        <div className="secition-1-bg-about">
          <h1 className="section-1-header-about">Contact Us</h1>
        </div>
      </section>
      <section style={{ padding: "5%" }}>
        <div className="treat_div">
          <p className="treat-txt">Location</p>
          <h1
            className="featured-txt"
            style={{ fontSize: "18px", fontWeight: "400" }}
          >
            Our clinic is conveniently located in the heart of Martinsburg
            allowing for easy accessibility and convenience.
          </h1>
        </div>
        <div className="contact-res">
          <div className="iframe_sec">
            <iframe
              style={{
                marginBottom: "60px",
                position: "relative",
                top: "25px",
                width: "100%",
              }}
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3079.7538159245796!2d-77.97135!3d39.474889999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMznCsDI4JzI5LjYiTiA3N8KwNTgnMTYuOSJX!5e0!3m2!1sen!2s!4v1715450953305!5m2!1sen!2s"
              width="600"
              height="450"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div>
            <p style={{ fontWeight: "500", fontSize: "24px" }}>Opening Hours</p>
            <p
              className="margin_btn"
              style={{ fontWeight: "500", fontSize: "20px" }}
            >
              Monday:{" "}
              <span
                className="time text-end"
                style={{ fontWeight: "500", color: "black" }}
              >
                8:00 am - 4:30 pm
              </span>
            </p>
            <p
              className="margin_btn"
              style={{ fontWeight: "500", fontSize: "20px" }}
            >
              Tuesday:{" "}
              <span
                className="time"
                style={{ fontWeight: "500", color: "black" }}
              >
                8:00 am - 4:30 pm
              </span>
            </p>
            <p
              className="margin_btn"
              style={{ fontWeight: "500", fontSize: "20px" }}
            >
              Wednesday:{" "}
              <span
                className="time"
                style={{ fontWeight: "500", color: "black" }}
              >
                8:00 am - 4:30 pm
              </span>
            </p>
            <p
              className="margin_btn"
              style={{ fontWeight: "500", fontSize: "20px" }}
            >
              Thursday:{" "}
              <span
                className="time"
                style={{ fontWeight: "500", color: "black" }}
              >
                8:00 am - 4:30 pm
              </span>
            </p>
            <p
              className="margin_btn"
              style={{ fontWeight: "500", fontSize: "20px" }}
            >
              Friday:{" "}
              <span
                className="time"
                style={{ fontWeight: "500", color: "black" }}
              >
                8:00 am - 4:30 pm
              </span>
            </p>
            <p
              className="margin_btn"
              style={{ fontWeight: "500", fontSize: "20px" }}
            >
              Saturday:{" "}
              <span
                className="time"
                style={{ fontWeight: "500", color: "black" }}
              >
                Closed
              </span>
            </p>
            <p
              className="margin_btn"
              style={{ fontWeight: "500", fontSize: "20px" }}
            >
              Sunday:{" "}
              <span
                className="time"
                style={{ fontWeight: "500", color: "black" }}
              >
                Closed
              </span>
            </p>
            <p
              className="margin_btn"
              style={{ fontWeight: "500", fontSize: "20px", marginTop: "20px" }}
            >
              Get in Touch
            </p>
            <p
              className="margin_btn"
              style={{
                color: "#6D6D6D",
                fontSize: "16px",
                alignItems: "Start",
              }}
            >
              <b>Address:</b> <span className="text-end">1008 Tavern Road, Suite 201,
              <br /> Martinsburg, WV 25401</span>
            </p>
            <p
              className="margin_btn"
              style={{ color: "#6D6D6D", fontSize: "16px" }}
            >
              <b>Phone number:</b> +1 681-446-7093
            </p>
            <p
              className="margin_btn"
              style={{ color: "#6D6D6D", fontSize: "16px" }}
            >
              <b>Fax Number:</b> +1 681-446-7094
            </p>
            <p
              className="margin_btn"
              style={{ color: "#6D6D6D", fontSize: "16px" }}
            >
              <b>Practice Email: </b> info@charterderm.com
            </p>
          </div>
        </div>
        <div>
          <p className="contact_us">Send Us a Message</p>
          {/* <p className="contact_us_tit">Doctor*</p>
          <select
            style={{ width: "43%" }}
            className="selcect_design"
            name="doctor"
            onChange={handleChange}
          >
            <option value="Dr. Suresh">Dr. Suresh</option>
            <option value="Dr. Amir">Dr. Amir</option>
          </select> */}
          <p className="contact_us_tit">Name*</p>
          <input
            className="selcect_design"
            type="text"
            name="name"
            onChange={handleChange}
          />
          <p className="contact_us_tit">Email*</p>
          <input
            className="selcect_design"
            type="email"
            name="email"
            onChange={handleChange}
          />
          <p className="contact_us_tit">Phone*</p>
          <input
            className="selcect_design"
            type="text"
            name="phone_number"
            onChange={handleChange}
          />
          <p className="contact_us_tit">Message*</p>
          <textarea
            rows={4}
            className="selcect_design"
            name="message"
            onChange={handleChange}
          />
          <br />
          <br />
          <br />
          <button className="submit_btn" onClick={() => handleSubmit()}>
            Submit
          </button>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default About;