import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/logo.png";
import hamburger from "../assets/hamburger.jpg";
import { Col, Dropdown, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FaClock } from 'react-icons/fa';

function Header() {
 

  return (
    <>
    <div className='d-none d-md-block'>
      <div className='header_bg'>
        <img src={logo} className='logo' alt='wait' />
        <img src={hamburger} className='hamburger' alt='wait'  />
      
          <div className='Nav-links'>
            <Link className='a_a' to="/">Home</Link>
            <Link className='a_a' to="/about">About Us</Link>
            <Link className='a_a' to="/services">Services</Link>
            <Link className='a_a' to="/physician">Our Physicians</Link>
            <div className='a_a'>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Patient's Resources
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="https://sadio.com/login">Patient Portal</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">HIPAA Policy</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Pay Your Bill</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Link className='a_a' to="/contact-us">Contact Us</Link>
          </div>
        
      </div>
      </div>
      <div className='responsive-nav d-md-none'>
      {[false].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
          <Container fluid>
            <Navbar.Brand href="#">
            <img src={logo} className='logo' alt='wait' />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <img src={logo} className='logo' alt='wait' />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                <Link className='a_a mb-3' style={{fontSize:"18px"}} to="/">Home</Link>
            <Link className='a_a mb-3' style={{fontSize:"18px"}} to="/about">About Us</Link>
            <Link className='a_a mb-3' style={{fontSize:"18px"}} to="/services">Services</Link>
            <Link className='a_a mb-3' style={{fontSize:"18px"}} to="/physician">Our Physicians</Link>
            <Link className='a_a mb-3' style={{fontSize:"18px"}} to="https://sadio.com/login">Patient's Portal</Link>
            <Link className='a_a mb-3' style={{fontSize:"18px"}} to="/contact-us">Contact Us</Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
      </div>
    </>
  );
}

export default Header;
